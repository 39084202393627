class TextExplosion {
  constructor(trigger, target) {
    this.state = false;
    this.trigger = trigger;
    this.target = target;
    this.isBuilt = false;

    this.handleTrigger();
    this.handleScroll();
  }

  handleScroll() {
    window.addEventListener("scroll", () => {
      if (!this.isBuilt) {
        this.trigger.classList.add("active");

        const rotate = window.scrollY / 2;

        this.trigger.style.transform = `rotate(-${rotate}deg)`;
        this.trigger.parentNode.style.transform = `rotate(${rotate}deg)`;
      }
    });
  }

  handleTrigger() {
    this.trigger.addEventListener("click", () => {
      if (this.state) {
        this.handleExplosion(false);
      } else {
        if (!this.isBuilt) {
          this.buildExplosions();

          if (this.trigger.classList.contains("active")) {
            this.trigger.style.transform = "none";
            this.trigger.parentNode.style.transform = `none`;
          }
        } else {
          this.handleExplosion();
        }
      }

      this.state = !this.state;
    });
  }

  handleExplosion(on = true) {
    on
      ? this.target.parentNode.classList.remove("diffused")
      : this.target.parentNode.classList.add("diffused");
  }

  handleRandom() {
    return (
      (Math.random() < 0.5 ? -1 : 1) *
      Math.floor(Math.random() * Math.floor(500))
    );
  }

  buildExplosions() {
    const targetNodes = [...this.target.querySelectorAll("*")];

    for (let node of targetNodes) {
      if (node.classList.contains("skip-explosion")) continue;

      let str = "";
      for (let char of node.innerHTML) {
        str +=
          char !== " "
            ? `<span class="explosion" style="transform: translate(${this.handleRandom()}px, ${this.handleRandom()}px) scale(0);">${char}</span>`
            : "<span> </span>";
      }

      node.innerHTML = str;
    }

    this.isBuilt = true;
    setTimeout(() => {
      this.handleExplosion();
    }, 20);
  }
}

export const handleProfile = () => {
  const trigger = document.querySelector(".view-about");
  const target = document.querySelector(".banner-content");

  new TextExplosion(trigger, target);
};
class Timeline {
  constructor(filter, timeline) {
    this.filter = filter;
    this.timeline = timeline;
    this.activeFilter = "";
    this.filterBorder = filter.querySelector(".filter-border");

    this.onFilterClick();

    setTimeout(() => {
      this.handleFilterState();
    }, 200);

    this.handleScroll();
    this.handleResize();
  }

  handleScroll() {
    window.addEventListener("scroll", () => {
      const foundItem = this.timeline.querySelector(
        ".timeline-item.active:not(.scroll-active)"
      );

      if (
        foundItem &&
        foundItem.offsetTop - window.scrollY - 50 <= window.innerHeight
      ) {
        this.handleActiveItem(foundItem);
      }
    });
  }

  handleActiveItem(item) {
    if (item && !this.stop) {
      item.classList.remove("scroll-left");
      item.classList.remove("scroll-right");

      item.classList.add("scroll-active");
      this.setMaxHeight(item);

      const foundItemIndex = this.getActiveItemIndex(item);

      item.classList.add(
        `scroll-${foundItemIndex % 2 === 0 ? "left" : "right"}`
      );

      this.stop = true;

      setTimeout(() => {
        this.stop = false;
      }, 400);
    }
  }

  handleResize() {
    window.addEventListener("resize", () => this.handleFilterState());
  }

  onFilterClick() {
    const timelineSection = document.querySelector(".timeline-section");

    this.filter.querySelectorAll(".filter").forEach((filter) => {
      filter.addEventListener("click", () => {
        if (this.stop) return;

        const filterType = filter.dataset.filter;

        if (this.activeFilter === filterType) return;

        this.activeFilter = filterType;

        window.scrollTo({
          top: timelineSection.offsetTop,
          left: 0,
        });

        this.handleFiltration();
        this.handleFilterState();
      });
    });
  }

  handleFilterState() {
    const activeFilter = this.filter.querySelector(
      `.filter[data-filter="${this.activeFilter}"]`
    );

    const borderWidth =
      activeFilter.offsetWidth + activeFilter.offsetWidth / 10;
    const borderColor = window
      .getComputedStyle(activeFilter)
      .getPropertyValue("color");

    this.filterBorder.style.left = `${
      activeFilter.offsetLeft - borderWidth * 0.05
    }px`;
    this.filterBorder.style.top = `${activeFilter.offsetTop + 2.5}px`;
    this.filterBorder.style.width = `${borderWidth}px`;
    this.filterBorder.style.backgroundColor = borderColor;
  }

  handleFiltration() {
    this.timeline.querySelectorAll(`.scroll-active`).forEach((item) => {
      item.classList.remove("scroll-active");
      item.style.maxHeight = 0;
    });

    const firstFilteredItem = this.timeline.querySelector(
      this.activeFilter ? `.timeline-${this.activeFilter}` : ".timeline-item"
    );

    this.handleActiveItem(firstFilteredItem);

    this.timeline.querySelectorAll(`.timeline-item`).forEach((timelineItem) => {
      if (
        !this.activeFilter ||
        timelineItem.classList.contains(`timeline-${this.activeFilter}`)
      ) {
        timelineItem.classList.add("active");

        if (timelineItem.classList.contains("scroll-active")) {
          this.setMaxHeight(timelineItem);
        }
      } else {
        timelineItem.classList.remove("active");
        timelineItem.style.maxHeight = 0;
      }
    });
  }

  setMaxHeight(item) {
    item.style.maxHeight = `2000px`;
  }

  getActiveItemIndex(item) {
    const scrollActiveItems = this.timeline.querySelectorAll(".scroll-active");

    return Array.from(scrollActiveItems).indexOf(item);
  }
}

export const handleTimeline = () => {
  const timelineFilter = document.querySelector(".timeline-filter");
  const timeline = document.querySelector(".timeline");

  new Timeline(timelineFilter, timeline);
};

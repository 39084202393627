export const handleScroller = () => {
    const scroller = document.querySelector('.scroll')
    const timeline = document.querySelector('.timeline-section')

    scroller.addEventListener('click', () => {
        window.scrollTo({
            top: timeline.offsetTop,
            left: 0,
            behavior: 'smooth'
        })
    })
}
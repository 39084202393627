import { handleProfile } from "./classes/profile";
import { handleTimeline } from "./classes/timeline";
import { handleScroller } from "./classes/scroller";
import { DateTime } from "luxon";

(() => {
  const timer = document.querySelector("#timer");
  const { days, years } = DateTime.now().diff(
    DateTime.fromISO(timer.dataset.date),
    ["days", "years"]
  );

  timer.innerHTML = `${years} Years and ${Math.round(days)} Days`;

  handleProfile();
  handleTimeline();
  handleScroller();
})();
